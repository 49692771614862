// React
import React, {
    lazy,
} from 'react';

// Font
import '@fontsource/roboto';

const ChatBot = lazy(() => import('./ChatBot' /* webpackChunkName: "ChatBot" */).catch(e => window.location.reload()));

const App = () => {

    let base = (document.getElementById('react-main') !== null ?
                <ChatBot
                    embed={true}
                    element='chat-container'
                />
                : null);

    let embed = (document.getElementById('react-chatbot') !== null ?
                <ChatBot
                    embed={true}
                    element='react-chatbot'
                />
                : null);

    return (
        <React.Fragment>
            {base}
            {embed}
        </React.Fragment>
    );
};

export default App;
